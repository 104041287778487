import PageHeader from '../components/PageHeader';
import PhotoJoseph from '../assets/photos/photo-joseph.jpeg';
import PhotoStan from '../assets/photos/photo-stan.jpg';

import styled from 'styled-components';

const Photo = styled.img`
  max-width: 100%;
  height: auto; 
`;

const ParentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ProfileContainer = styled.div`
  padding: 10px 0 10px min(50px, 10%);  
  width: 400px;
  font-size: 17px;
  @media screen and (max-width: 600px) {
    width: 300px;
  }
`;

const SubContainer = styled.div`

`;

const About = () => {
  return (
    <>
      <PageHeader pageName="About Us"/>
        <ParentContainer>
        <ProfileContainer>
          <h2>Joseph C. Ong</h2>
            <p>
            Joseph is an enthusiastic Role Playing Games (RPG) gamer. Since his teenage years until today, he has been playing a lot of RPGs, thus making him rooted in RPGs to the stage that he is now confident in developing his own RPG. Joseph's specialty is in developing the game storyline, gameplay, character concept, character background and so on.
            </p>
<p>Growing up playing RPGs has eventually shaped him to become someone who has a vision in developing his own games. With the support from his team, Joseph believes that Dikaios Studios is capable of making great games in future.</p>
        </ProfileContainer>
        <ProfileContainer>
            <h2>Stanley Darmawan</h2>
              <p>
              Stanley started his career as an Animator, back in 2006. He had worked in gaming, filming and advertisement industries. Then in 2016 he decided to change career to become a software engineer, up until now. His expertise includes Unity game development, mobile app and website developments.
              </p>
              <p>
              He always loves playing Role Playing Games (RPG), both 2D and 3D styles. With his background in animation and software engineering, he’s very motivated in developing his own game.
              </p>
        </ProfileContainer>
        </ParentContainer>
    </>
  )
}

export default About