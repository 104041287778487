import styled from 'styled-components';

const FooterContainer = styled.div`
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
`;

const Footer = () => {
    return (
        <>
            <FooterContainer>
                <br />
                <p>
                    ©2024 Dikaios Studios Pty Ltd <br></br>
                    ABN: 24 663 775 040
                </p>
            </FooterContainer>
        </>
    );
};

export default Footer;